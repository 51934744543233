import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { LocalStorageService, SessionStorageService, StorageService } from '@app/core/storage/universal.inject';
import {
    AuthenticationDataService,
    AuthenticationState,
    AuthenticationStatusEnum,
    RelationDataService,
    RelationInterface,
} from 'outshared-lib';
import { BehaviorSubject, Observable } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';

/** @deprecated use LegacyAuthenticationCompatibilityService from libs */
@Injectable({
    providedIn: 'root',
})
export class LegacyAuthenticationCompatibilityService {
    private renderer: Renderer2 = this.rendererFactory.createRenderer(null, null);

    private legacyInStorageSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public legacyInStorage$: Observable<boolean> = this.legacyInStorageSubject.asObservable();

    constructor(
        @Inject(StorageService) private storage: Storage,
        @Inject(LocalStorageService) private localStorage: Storage,
        @Inject(SessionStorageService) private sessionStorage: Storage,
        @Inject(DOCUMENT) private document: Document,
        private readonly authenticationDataService: AuthenticationDataService,
        private readonly relationDataService: RelationDataService,
        private rendererFactory: RendererFactory2
    ) {}

    public init(): void {
        this.relationDataService
            .getRelation$()
            .pipe(withLatestFrom(this.authenticationDataService.getState$()))
            .subscribe(([relation, state]) => this.setLegacyStorage([relation, state]));
    }

    private setLegacyStorage([relation, state]: [RelationInterface, AuthenticationState]): void {
        if (state.status === AuthenticationStatusEnum.LoggedIn && relation) {
            const oldRelation = this.mapLegacyRelation(state, relation);
            this.renderer.addClass(this.document.body, 'is-loggedin');
            this.storage.setItem('ls.autorisatie', JSON.stringify(oldRelation));
            this.localStorage.setItem('fromUsers.state', JSON.stringify(this.mapLegacyUser(state, relation)));
            this.sessionStorage.setItem('ingelogd', JSON.stringify(true));
            this.legacyInStorageSubject.next(true);
        } else {
            this.renderer.removeClass(this.document.body, 'is-loggedin');
            this.storage.removeItem('ls.autorisatie');
            this.localStorage.removeItem('fromUsers.state');
            this.sessionStorage.removeItem('ingelogd');
            this.legacyInStorageSubject.next(false);
        }
    }

    private mapLegacyRelation(state: AuthenticationState, relation: RelationInterface): object {
        return {
            relatie_id: relation?.relation_id,
            naam: relation?.name,
            emailadres: relation?.primary_email_address?.email_address,
            gezinssamenstelling: relation?.family_composition?.family_composition_code,
            geslacht_code: relation?.gender_code,
            achternaam: relation?.surname,
            tussenvoegsels: relation?.middle_name_initials,
            voorletters: relation?.initials,
            opt_out_moment: relation?.opt_out_timestamp,

            status_code: state.status,
            token: state?.token,
            device: null,
        };
    }

    private mapLegacyUser(state: AuthenticationState, relation: RelationInterface): object {
        return {
            authenticated: state?.status === AuthenticationStatusEnum.LoggedIn,
            loaded: !!relation,
            loading: state?.loginPending,
            signout: state?.logoutPending,
            credentials: state?.credentials,
            expires: state?.sessionStart,
            user: this.mapLegacyRelation(state, relation),
        };
    }
}
